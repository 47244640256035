<template>
    <div>
        <div v-for="cmsBlock in cmsBlocks" :key="cmsBlock.__typename + cmsBlock.id">
            <component :is="componentMap[cmsBlock.__typename]" :cmsBlock="cmsBlock"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ArticlePageBuilder',

        props: [
            'cmsBlocks'
        ],

        data() {
            return {
                componentMap: {
                    ComponentPageBuilderImageBlock: () => import('@/components/PageBuilder/ImageBlock'),
                    ComponentPageBuilderQuoteBlock: () => import('@/components/PageBuilder/QuoteBlock'),
                    ComponentPageBuilderWysiwyg: () => import('@/components/BlogArticleBuilder/TextBlock'),
                    ComponentPageBuilderSimplePodcastBlock: () => import('@/components/PageBuilder/SimplePodcastBlock'),
                    ComponentPageBuilderImageGallery: () => import('@/components/PageBuilder/ImageGallery'),
                    ComponentPageBuilderReachMemberForm: () => import('@/components/PageBuilder/ReachMemberForm'),
                    ComponentPageBuilderYouTube: () => import('@/components/PageBuilder/YouTube'),
                    ComponentPageBuilderX: () => import('@/components/PageBuilder/X'),
                    ComponentPageBuilderInstagram: () => import('@/components/PageBuilder/Instagram'),
                    ComponentPageBuilderLinkedIn: () => import('@/components/PageBuilder/LinkedIn'),
                    ComponentPageBuilderSpacerBlock: () => import('@/components/PageBuilder/SpacerBlock')
                },
                components: {}
            }
        },

        mounted() {
            for (const [key, value] of Object.entries(this.componentMap)) {
                value().then(module => {
                    this.$set(this.components, key, module.default);
                });
            }
        }
    }
</script>
